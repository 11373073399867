import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '../components/Auth/LoginForm';
import '../styles/pages/login.css';

const LoginPage = () => {
    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-header">
                    <h1 className="brand-name">Taqqosla</h1>
                    <p className="brand-subtitle">Seller Portal</p>
                </div>
                <LoginForm />
                <div className="login-footer">
                    <p>Don't have an account? <Link to="/register">Register</Link></p>
                    <p className="help-text">
                        Need help? <Link to="/contact">Contact Support</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginPage; 