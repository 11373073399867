import React, { useState } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/AISlugGenerator.css';

const AIUrlChecker = ({ selectedProducts, onComplete }) => {
    const [isChecking, setIsChecking] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const checkUrls = async () => {
        setIsChecking(true);
        setError(null);
        const token = localStorage.getItem('accessToken');

        try {
            let index = currentIndex;
            const total = selectedProducts.length;

            while (index < total) {
                const productId = selectedProducts[index];

                // First get the product URL
                const productResponse = await axios.get(
                    `${config.apiUrl}/api/products/products/${productId}/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );

                const productUrl = productResponse.data.url;
                if (productUrl) {
                    // Check URL availability
                    const response = await axios.get(
                        `${config.apiUrl}/api/products/check-url/`,
                        {
                            params: { url: productUrl },
                            headers: { 'Authorization': `Bearer ${token}` }
                        }
                    );

                    // Update status based on URL validity
                    if (response.data.is_valid) {
                        // URL is valid - change status to PUBLISHED
                        await axios.patch(
                            `${config.apiUrl}/api/products/products/${productId}/`,
                            { status: 'PUBLISHED' },
                            {
                                headers: { 'Authorization': `Bearer ${token}` }
                            }
                        );
                    } else {
                        // URL is not valid - change status to OUT_OF_STOCK
                        await axios.patch(
                            `${config.apiUrl}/api/products/products/${productId}/`,
                            { status: 'OUT_OF_STOCK' },
                            {
                                headers: { 'Authorization': `Bearer ${token}` }
                            }
                        );
                    }
                }

                index++;
                setCurrentIndex(index);
                setProgress((index / total) * 100);
            }

            onComplete();
        } catch (error) {
            setError('Failed to check URLs: ' + error.message);
        } finally {
            setIsChecking(false);
            setCurrentIndex(0);
        }
    };

    return (
        <div className="ai-generator-container">
            <button
                className="ai-url-check-button"
                onClick={checkUrls}
                disabled={isChecking || selectedProducts.length === 0}
            >
                {isChecking
                    ? `Checking...`
                    : 'Check Products'}
            </button>

            {isChecking && (
                <div className="progress-container">
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                    <div className="progress-text">
                        {`${currentIndex} of ${selectedProducts.length} completed`}
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default AIUrlChecker; 