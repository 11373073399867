import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/AISlugGenerator.css';

const AISlugGenerator = ({ selectedProducts, onComplete }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 0 });
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;

    const generateSlugs = useCallback(async () => {
        if (!selectedProducts.length) return;

        setIsGenerating(true);
        setError(null);
        setProgress({ current: 0, total: selectedProducts.length });

        let currentIndex = 0;
        const token = localStorage.getItem('accessToken');

        while (currentIndex < selectedProducts.length) {
            try {
                const response = await axios.post(
                    `${config.apiUrl}/api/products/products/batch_generate_slugs/`,
                    {
                        product_ids: selectedProducts,
                        current_index: currentIndex
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 30000
                    }
                );

                if (response.data.is_complete) {
                    setProgress({ current: selectedProducts.length, total: selectedProducts.length });
                    break;
                }

                const batchSize = response.data.current - currentIndex;
                for (let i = 1; i <= batchSize; i++) {
                    const newCurrent = currentIndex + i;
                    setProgress(prev => ({
                        ...prev,
                        current: newCurrent
                    }));

                    await new Promise(resolve => setTimeout(resolve, 100));
                }

                localStorage.setItem('slugGeneratorProgress', JSON.stringify({
                    current: response.data.current,
                    total: response.data.total,
                    productIds: selectedProducts
                }));

                currentIndex = response.data.current;
                setRetryCount(0);

            } catch (error) {
                console.error('Error generating slugs:', error);
                const newRetryCount = retryCount + 1;
                setRetryCount(newRetryCount);

                if (newRetryCount >= MAX_RETRIES) {
                    setError(`Failed to generate slugs after ${MAX_RETRIES} attempts. Please try again.`);
                    break;
                }

                await new Promise(resolve => setTimeout(resolve, Math.pow(2, newRetryCount) * 1000));
                continue;
            }

            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        localStorage.removeItem('slugGeneratorProgress');
        setIsGenerating(false);
        if (onComplete) onComplete();
    }, [selectedProducts, retryCount, MAX_RETRIES, onComplete]);

    useEffect(() => {
        const savedProgress = localStorage.getItem('slugGeneratorProgress');
        if (savedProgress) {
            const { current, total, productIds } = JSON.parse(savedProgress);
            setProgress({ current, total });
            if (productIds.toString() === selectedProducts.toString()) {
                setIsGenerating(true);
                generateSlugs();
            } else {
                localStorage.removeItem('slugGeneratorProgress');
            }
        }
    }, [generateSlugs, selectedProducts]);

    return (
        <div className="ai-slug-generator">
            <button
                onClick={generateSlugs}
                disabled={isGenerating || !selectedProducts.length}
                className="ai-slug-button"
            >
                {isGenerating ? 'Generating...' : 'Generate AI Slugs'}
            </button>

            {isGenerating && (
                <div className="progress-container">
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                    </div>
                    <div className="progress-text">
                        {progress.current} of {progress.total} completed
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default AISlugGenerator; 