import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/products.css';

const EditUserPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        role: '',
        logo: ''
    });

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(`${config.apiUrl}/api/users/${userId}/`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                setFormData(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching user:', err);
                setError('Failed to load user');
                setLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('accessToken');
            await axios.put(`${config.apiUrl}/api/users/${userId}/`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            navigate('/dashboard/users');
        } catch (err) {
            console.error('Error updating user:', err);
            setError('Failed to update user');
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                const token = localStorage.getItem('accessToken');
                await axios.delete(`${config.apiUrl}/api/users/${userId}/`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                navigate('/dashboard/users');
            } catch (err) {
                console.error('Error deleting user:', err);
                setError('Failed to delete user');
            }
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="add-product-page">
            <div className="add-product-header">
                <h2>Edit User</h2>
                <button className="back-button" onClick={() => navigate('/dashboard/users')}>
                    <i className="fas fa-arrow-left"></i>
                    Back to Users
                </button>
            </div>

            <form className="add-product-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Username *</label>
                    <input
                        type="text"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>Email *</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>First Name</label>
                    <input
                        type="text"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <label>Last Name</label>
                    <input
                        type="text"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <input
                        type="text"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                    />
                </div>

                <div className="form-group">
                    <label>Role *</label>
                    <select
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                        required
                    >
                        <option value="ADMIN">Admin</option>
                        <option value="SELLER">Seller</option>
                        <option value="CUSTOMER">Customer</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>Logo URL</label>
                    <input
                        type="url"
                        name="logo"
                        value={formData.logo || ''}
                        onChange={handleChange}
                        placeholder="Enter logo URL"
                    />
                </div>

                <div className="form-actions">
                    <button
                        type="button"
                        className="cancel-button"
                        onClick={() => navigate('/dashboard/users')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="delete-button"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update User'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditUserPage; 