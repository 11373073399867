import axios from 'axios';
import { config } from '../config';

export const authService = {
    register: async (userData) => {
        try {
            const registerData = {
                username: userData.email.split('@')[0],
                email: userData.email,
                password: userData.password,
                first_name: userData.first_name,
                last_name: userData.last_name,
                phone_number: userData.phone_number,
                role: 'CUSTOMER'
            };

            console.log('Sending registration data:', registerData);

            const registerResponse = await axios.post(
                `${config.apiUrl}/api/users/register/`,
                registerData
            );

            if (registerResponse.data.token) {
                localStorage.setItem('authToken', registerResponse.data.token);

                await axios.post(
                    `${config.apiUrl}/api/dashboard/users/${registerResponse.data.id}/change_role/`,
                    { role: 'SELLER' },
                    {
                        headers: {
                            'Authorization': `Bearer ${registerResponse.data.token}`
                        }
                    }
                );
            }

            return registerResponse.data;
        } catch (error) {
            console.log('Full error response:', error.response);
            console.log('Error data:', error.response?.data);

            if (error.response?.data) {
                throw error.response.data;
            }
            throw error.message;
        }
    },

    login: async (credentials) => {
        try {
            const response = await axios.post(
                `${config.apiUrl}/api/users/login/`,
                credentials
            );

            if (response.data.access && response.data.refresh) {
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);

                // Fetch user data silently
                await authService.getCurrentUser();
            }

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    logout: () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('userData');
    },

    getCurrentUser: async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                return null;
            }

            const response = await axios.get(`${config.apiUrl}/api/users/me/`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            const userData = response.data;
            localStorage.setItem('userData', JSON.stringify(userData));
            return userData;
        } catch (error) {
            console.error('Error fetching user data:', error);
            return null;
        }
    },

    isAuthenticated: () => {
        const token = localStorage.getItem('accessToken');
        return !!token;
    }
}; 