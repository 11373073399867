import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/addCategory.css';

const EditCategoryPage = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [category, setCategory] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        slug: '',
        description: '',
        parent: ''
    });

    const fetchCategory = useCallback(async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                navigate('/login');
                return;
            }

            const response = await axios.get(
                `${config.apiUrl}/api/products/categories/${slug}/`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            const categoryData = response.data;
            setCategory(categoryData);
            setFormData({
                name: categoryData.name || '',
                slug: categoryData.slug || '',
                description: categoryData.description || '',
                parent: categoryData.parent || ''
            });
            setError('');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to load category');
        } finally {
            setLoading(false);
        }
    }, [slug, navigate]);

    useEffect(() => {
        fetchCategory();
    }, [fetchCategory]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const token = localStorage.getItem('accessToken');
            if (!token || !category) {
                navigate('/login');
                return;
            }

            const payload = {
                name: formData.name,
                slug: formData.slug,
                description: formData.description,
                parent: formData.parent || null
            };

            await axios.put(
                `${config.apiUrl}/api/products/categories/${category.slug}/`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            navigate('/dashboard/categories');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to update category');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this category? This action cannot be undone.')) {
            try {
                const token = localStorage.getItem('accessToken');

                await axios.delete(
                    `${config.apiUrl}/api/products/categories/${slug}/`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                navigate('/dashboard/categories');
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to delete category');
            }
        }
    };

    if (loading) {
        return (
            <div className="add-category-page">
                <div className="loading-state">
                    <i className="fas fa-spinner fa-spin"></i>
                    <p>Loading category...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="add-category-page">
            <div className="add-category-header">
                <h2>Edit Category</h2>
                <button
                    className="back-button"
                    onClick={() => navigate('/dashboard/categories')}
                >
                    <i className="fas fa-arrow-left"></i> Back
                </button>
            </div>

            {error && <div className="error-message">{error}</div>}

            <form onSubmit={handleSubmit} className="add-category-form">
                <div className="form-group">
                    <label>Category Name *</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        maxLength="200"
                        placeholder="Enter category name"
                    />
                </div>

                <div className="form-group">
                    <label>Category Slug *</label>
                    <input
                        type="text"
                        name="slug"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                        maxLength="200"
                        placeholder="Enter category slug"
                    />
                </div>

                <div className="form-group">
                    <label>Description</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        placeholder="Enter category description"
                        rows="4"
                    />
                </div>

                <div className="form-actions">
                    <button
                        type="button"
                        className="cancel-button"
                        onClick={() => navigate('/dashboard/categories')}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="delete-button"
                        onClick={handleDelete}
                    >
                        Delete
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                    >
                        {loading ? 'Updating...' : 'Update Category'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditCategoryPage; 