import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/pages/dashboard/products.css';
import axios from 'axios';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';
import AISlugGenerator from '../../pages/Dashboard/AISlugGenerator';
import '../../styles/pages/dashboard/AISlugGenerator.css';
import AIDescriptionGenerator from './AIDescriptionGenerator';
import AIReviewGenerator from './AIReviewGenerator';
import AIUrlChecker from './AIUrlChecker';
import useDebounce from '../../hooks/useDebounce';

const DraftProductsPage = () => {
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0);
    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [activeMenu, setActiveMenu] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(100);
    const [selectedSeller, setSelectedSeller] = useState('all');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isBulkActionsLoading, setIsBulkActionsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [sellers, setSellers] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const debouncedSearchQuery = useDebounce(searchTerm, 1000);

    const viewOptions = [100, 200, 300, 500, 1000, 2000, 3000, 5000];

    // Calculate total pages
    const totalPages = Math.ceil(totalCount / itemsPerPage);

    // Separate function to fetch sellers
    const fetchSellers = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.get(
                `${config.apiUrl}/api/products/products/sellers/`,
                {
                    params: {
                        limit: 1000,  // Add a high limit to get all sellers
                        offset: 0
                    },
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            // Debug log
            console.log('Sellers response:', response.data);

            // Check if response.data is paginated
            const sellersData = response.data.results || response.data;
            setSellers(sellersData);
        } catch (err) {
            console.error('Error fetching sellers:', err);
        }
    };

    // Add function to fetch categories
    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.get(
                `${config.apiUrl}/api/products/categories/`,
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );
            setCategories(response.data.data || []); // API returns categories in data property
        } catch (err) {
            console.error('Error fetching categories:', err);
        }
    };

    // Separate fetch functions for initial load and search
    const fetchInitialData = useCallback(async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('accessToken');
            const offset = (currentPage - 1) * itemsPerPage;

            const response = await axios.get(
                `${config.apiUrl}/api/products/products/category_filtered_products/`,  // Use this endpoint
                {
                    params: {
                        category_slug: 'draft',
                        offset: offset,
                        limit: itemsPerPage,
                        seller: selectedSeller !== 'all' ? selectedSeller : undefined
                    },
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            setProducts(response.data.results);
            setTotalCount(response.data.total_count);
            setLoading(false);
        } catch (err) {
            setError('Failed to load products');
            setLoading(false);
        }
    }, [currentPage, itemsPerPage, selectedSeller]);

    const fetchSearchResults = useCallback(async () => {
        if (!debouncedSearchQuery) return;

        try {
            setIsSearching(true);
            const token = localStorage.getItem('accessToken');
            const offset = (currentPage - 1) * itemsPerPage;

            const response = await axios.get(
                `${config.apiUrl}/api/products/products/category_filtered_products/`,  // Use this endpoint
                {
                    params: {
                        category_slug: 'draft',
                        offset: offset,
                        limit: itemsPerPage,
                        search: debouncedSearchQuery,
                        seller: selectedSeller !== 'all' ? selectedSeller : undefined
                    },
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            setProducts(response.data.results);
            setTotalCount(response.data.total_count);
        } catch (err) {
            console.error('Error fetching search results:', err);
            setError('Failed to load search results');
        } finally {
            setIsSearching(false);
        }
    }, [debouncedSearchQuery, currentPage, itemsPerPage, selectedSeller]);

    // Effect for initial data load
    useEffect(() => {
        if (debouncedSearchQuery) {
            fetchSearchResults();
        } else {
            fetchInitialData();
        }
    }, [debouncedSearchQuery, fetchSearchResults, fetchInitialData]);

    // Reset page when search term changes
    useEffect(() => {
        setCurrentPage(1);
    }, [debouncedSearchQuery]);

    // Use products directly from state
    const currentProducts = products;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Reset selected products when changing page
        setSelectedProducts([]);
    };

    // eslint-disable-next-line no-unused-vars
    const handleMenuClick = (productId) => {
        setActiveMenu(activeMenu === productId ? null : productId);
    };

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = Number(e.target.value);
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to first page
        setSelectedProducts([]); // Reset selected products
    };

    const handleSelectProduct = (productId) => {
        setSelectedProducts(prev => {
            const newSelection = prev.includes(productId)
                ? prev.filter(id => id !== productId)
                : [...prev, productId];
            return newSelection;
        });
    };

    const handleSelectAll = () => {
        setSelectedProducts(prev =>
            prev.length === currentProducts.length ? [] : currentProducts.map(p => p.id)
        );
    };

    const handleBulkCategoryChange = async (categoryId) => {
        if (!categoryId) return;

        if (window.confirm('Are you sure you want to change the category for selected products?')) {
            try {
                setIsBulkActionsLoading(true);
                await axios.post(`${config.apiUrl}/api/products/products/bulk_update/`, {
                    product_ids: selectedProducts,
                    category: parseInt(categoryId)
                }, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
                });
                await fetchInitialData();
                setSelectedProducts([]);
            } catch (error) {
                console.error('Failed to update categories:', error);
                alert(error.response?.data?.message || 'Failed to update categories');
            } finally {
                setIsBulkActionsLoading(false);
            }
        }
    };

    const handleBulkStatusChange = async (status) => {
        if (!status) return;

        if (window.confirm('Are you sure you want to change the status for selected products?')) {
            try {
                setIsBulkActionsLoading(true);
                await axios.post(`${config.apiUrl}/api/products/products/bulk_update/`, {
                    product_ids: selectedProducts,
                    status: status
                }, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
                });
                await fetchInitialData();
                setSelectedProducts([]);
            } catch (error) {
                console.error('Failed to update status:', error);
                alert(error.response?.data?.message || 'Failed to update status');
            } finally {
                setIsBulkActionsLoading(false);
            }
        }
    };

    const handleBulkDelete = async () => {
        if (window.confirm('Are you sure you want to delete selected products? This action cannot be undone.')) {
            try {
                setIsBulkActionsLoading(true);
                await axios.post(`${config.apiUrl}/api/products/products/bulk_delete/`, {
                    product_ids: selectedProducts
                }, {
                    headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
                });
                await fetchInitialData();
                setSelectedProducts([]);
            } catch (error) {
                console.error('Failed to delete products:', error);
                alert(error.response?.data?.message || 'Failed to delete products');
            } finally {
                setIsBulkActionsLoading(false);
            }
        }
    };

    // Add effect to handle bulk actions visibility
    useEffect(() => {
        // Show bulk actions only when products are selected
        if (selectedProducts.length > 0) {
            document.querySelector('.bulk-actions')?.classList.add('show');
        } else {
            document.querySelector('.bulk-actions')?.classList.remove('show');
        }
    }, [selectedProducts]);

    // Update the bulk actions section to work for both ADMIN and SELLER roles
    const renderBulkActions = () => {
        if (selectedProducts.length === 0) return null;

        return (
            <div className="bulk-actions">
                <select
                    onChange={(e) => handleBulkCategoryChange(e.target.value)}
                    className="bulk-action-select"
                    defaultValue=""
                    disabled={isBulkActionsLoading}
                >
                    <option value="">Change Category</option>
                    {Array.isArray(categories) && categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>

                <select
                    onChange={(e) => handleBulkStatusChange(e.target.value)}
                    className="bulk-action-select"
                    defaultValue=""
                    disabled={isBulkActionsLoading}
                >
                    <option value="">Change Status</option>
                    <option value="DRAFT">Draft</option>
                    <option value="PUBLISHED">Published</option>
                    <option value="OUT_OF_STOCK">Out of Stock</option>
                    <option value="ARCHIVED">Archived</option>
                </select>

                <button
                    onClick={handleBulkDelete}
                    className="delete-button"
                    disabled={isBulkActionsLoading}
                >
                    Delete Selected
                </button>
            </div>
        );
    };

    // Update the other useEffect
    useEffect(() => {
        const forceReload = localStorage.getItem('forceProductsReload');
        if (forceReload) {
            fetchInitialData();
            localStorage.removeItem('forceProductsReload');
        }

        const successData = localStorage.getItem('importSuccess');
        if (successData) {
            const { message, timestamp } = JSON.parse(successData);
            if (Date.now() - timestamp < 5000) {
                setSuccessMessage(message);
                setTimeout(() => setSuccessMessage(null), 5000);
            }
            localStorage.removeItem('importSuccess');
        }
    }, [fetchInitialData]);

    // Update the search input handler to not trigger loading state
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        // Remove any loading indicators here
        setIsSearching(false);
    };

    // Add this useEffect near the other useEffect hooks
    useEffect(() => {
        // Fetch user role from token
        const token = localStorage.getItem('accessToken');
        if (token) {
            axios.get(`${config.apiUrl}/api/users/me/`, {
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(response => {
                    setUserRole(response.data.role);
                    // Only fetch sellers if user is admin
                    if (response.data.role === 'ADMIN') {
                        fetchSellers();
                    }
                })
                .catch(error => {
                    console.error('Error fetching user role:', error);
                });
        }
    }, []); // Empty dependency array as this should only run once

    // Add another useEffect for categories
    useEffect(() => {
        // Fetch categories when component mounts
        fetchCategories();
    }, []); // Empty dependency array as this should only run once

    // Or alternatively, you can combine both effects if they're always needed together
    useEffect(() => {
        const initializeData = async () => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                try {
                    // Fetch user role
                    const userResponse = await axios.get(`${config.apiUrl}/api/users/me/`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setUserRole(userResponse.data.role);

                    // Fetch categories
                    await fetchCategories();

                    // Only fetch sellers if user is admin
                    if (userResponse.data.role === 'ADMIN') {
                        await fetchSellers();
                    }
                } catch (error) {
                    console.error('Error initializing data:', error);
                }
            }
        };

        initializeData();
    }, []); // Empty dependency array as this should only run once

    return (
        <div className="products-page">
            {successMessage && (
                <div className="success-message">
                    {successMessage}
                </div>
            )}
            <div className="page-header">
                <h1>Draft Products</h1>
                <button
                    className="submit-button new-product-btn"
                    onClick={() => navigate('/dashboard/products/add')}
                >
                    <i className="fas fa-plus"></i>
                    New Product
                </button>
            </div>


            <div className="products-controls">
                <div className="search-container">
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search products..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="search-input"
                        />
                        {debouncedSearchQuery && isSearching && (
                            <span className="search-loading">
                                <i className="fas fa-spinner fa-spin"></i>
                            </span>
                        )}
                    </div>
                </div>

                <div className="filters-container">
                    {(userRole === 'ADMIN' || userRole === 'SELLER') && (
                        <>
                            {userRole === 'ADMIN' && (
                                <select
                                    value={selectedSeller}
                                    onChange={(e) => setSelectedSeller(e.target.value)}
                                    className="seller-filter"
                                >
                                    <option value="all">All Sellers</option>
                                    {sellers.map(seller => (
                                        <option key={seller.id} value={seller.id}>
                                            {seller.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <select
                                value={itemsPerPage}
                                onChange={handleItemsPerPageChange}
                                className="view-filter"
                            >
                                {viewOptions.map(option => (
                                    <option key={option} value={option}>
                                        {option} per page
                                    </option>
                                ))}
                            </select>
                            {renderBulkActions()}
                        </>
                    )}
                </div>
            </div>

            {(loading && !searchTerm) ? (
                <div className="loading-state">
                    <i className="fas fa-spinner fa-spin"></i>
                    <p>Loading products...</p>
                </div>
            ) : error ? (
                <div className="error-state">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>{error}</p>
                </div>
            ) : (
                <>
                    <div className="products-table">
                        <div className="products-list">
                            <div className="products-table">
                                <div className="table-header">
                                    <div className="col-checkbox">
                                        <input
                                            type="checkbox"
                                            checked={selectedProducts.length === currentProducts.length}
                                            onChange={handleSelectAll}
                                        />
                                    </div>
                                    <div className="col-name">Product Name</div>
                                    <div className="col-status">Status</div>
                                    <div className="col-category">Category</div>
                                    <div className="col-views">Views</div>
                                    <div className="col-price">Price (UZS)</div>
                                </div>

                                {/* Show search loading indicator only after debounce */}
                                {debouncedSearchQuery && isSearching ? (
                                    <div className="loading-state search-only">
                                        <i className="fas fa-spinner fa-spin"></i>
                                        <p>Searching products...</p>
                                    </div>
                                ) : currentProducts.length > 0 ? (
                                    currentProducts.map(product => (
                                        <div
                                            key={product.id}
                                            className="product-item"
                                            onClick={(e) => {
                                                // Only navigate if we're not clicking the checkbox area
                                                if (!e.target.closest('.col-checkbox')) {
                                                    navigate(`/dashboard/products/edit/${product.id}`);
                                                }
                                            }}
                                        >
                                            <div
                                                className="col-checkbox"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={selectedProducts.includes(product.id)}
                                                    onChange={(e) => {
                                                        e.stopPropagation(); // Stop event from bubbling up
                                                        handleSelectProduct(product.id);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-name">{product.name}</div>
                                            <div className="col-status">
                                                <span className={`status-badge ${product.status?.toLowerCase()}`}>
                                                    {product.status}
                                                </span>
                                            </div>
                                            <div className="col-category">{product.category}</div>
                                            <div className="col-views">{product.view_count || 0}</div>
                                            <div className="col-price">{product.price?.toLocaleString()}</div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-results">
                                        <i className="fas fa-search"></i>
                                        <p>No products found</p>
                                        <span>Try adjusting your search to find what you're looking for.</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {totalPages > 1 && (
                <div className="pagination">
                    <button
                        className="pagination-btn"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>

                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index + 1}
                            className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}

                    <button
                        className="pagination-btn"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </div>
            )}

            {selectedProducts.length > 0 && (
                <div className="ai-tools">
                    <AISlugGenerator
                        selectedProducts={selectedProducts}
                        onComplete={() => {
                            setSelectedProducts([]);
                            fetchInitialData();
                        }}
                    />
                    <AIDescriptionGenerator
                        selectedProducts={selectedProducts}
                        onComplete={() => {
                            setSelectedProducts([]);
                            fetchInitialData();
                        }}
                    />
                    <AIReviewGenerator
                        selectedProducts={selectedProducts}
                        onComplete={() => {
                            setSelectedProducts([]);
                            fetchInitialData();
                        }}
                    />
                    <AIUrlChecker
                        selectedProducts={selectedProducts}
                        onComplete={() => {
                            setSelectedProducts([]);
                            fetchInitialData();
                        }}
                    />
                </div>
            )}
        </div >
    );
};

export default DraftProductsPage; 