import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardLayout from './components/Layout/DashboardLayout';
import ProductsPage from './pages/Dashboard/ProductsPage';
import CategoriesPage from './pages/Dashboard/CategoriesPage';
import SettingsPage from './pages/Dashboard/SettingsPage';
import RegisterPage from './pages/RegisterPage';
import AddProductPage from './pages/Dashboard/AddProductPage';
import EditProductPage from './pages/Dashboard/EditProductPage';
import AddCategoryPage from './pages/Dashboard/AddCategoryPage';
import EditCategoryPage from './pages/Dashboard/EditCategoryPage';
import ImportDataPage from './pages/Dashboard/ImportDataPage';
import ImportJsonPage from './pages/Dashboard/ImportJsonPage';
import UsersPage from './pages/Dashboard/UsersPage';
import EditUserPage from './pages/Dashboard/EditUserPage';
import AddUserPage from './pages/Dashboard/AddUserPage';
import DraftProductsPage from './pages/Dashboard/DraftProductsPage';
import './styles/variables.css';
import './styles/global.css';
import './styles/auth.css';
import './styles/pages/login.css';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />

                <Route path="/dashboard" element={<DashboardLayout />}>
                    <Route index element={<Navigate to="/dashboard/products" replace />} />
                    <Route path="products" element={<ProductsPage />} />
                    <Route path="categories" element={<CategoriesPage />} />
                    <Route path="import-csv" element={<ImportDataPage />} />
                    <Route path="import-json" element={<ImportJsonPage />} />
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="products/add" element={<AddProductPage />} />
                    <Route path="products/edit/:id" element={<EditProductPage />} />
                    <Route path="categories/add" element={<AddCategoryPage />} />
                    <Route path="categories/edit/:slug" element={<EditCategoryPage />} />
                    <Route path="users" element={<UsersPage />} />
                    <Route path="users/add" element={<AddUserPage />} />
                    <Route path="users/:userId" element={<EditUserPage />} />
                    <Route path="draft-products" element={<DraftProductsPage />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App; 