import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/auth.css';
import { authService } from '../../services/auth';

const RegisterForm = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        phone_number: '',
        role: 'seller'
    });
    const [errors, setErrors] = useState({});

    const roles = [
        { value: 'admin', label: 'Administrator' },
        { value: 'seller', label: 'Seller' },
        { value: 'user', label: 'User' }
    ];

    const validateForm = () => {
        const newErrors = {};

        // First Name validation
        if (!formData.first_name) {
            newErrors.first_name = 'First name is required';
        }

        // Last Name validation
        if (!formData.last_name) {
            newErrors.last_name = 'Last name is required';
        }

        // Email validation
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        // Password validation
        if (!formData.password) {
            newErrors.password = 'Password is required';
        } else if (formData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }

        // Confirm password
        if (formData.password !== formData.confirm_password) {
            newErrors.confirm_password = 'Passwords do not match';
        }

        // Phone validation
        if (!formData.phone_number) {
            newErrors.phone_number = 'Phone number is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await authService.register(formData);
                navigate('/dashboard/products');
            } catch (error) {
                // Handle different types of error responses
                if (typeof error === 'object') {
                    // If error contains field-specific errors
                    setErrors({
                        ...errors,
                        ...error,
                        general: 'Please correct the errors below.'
                    });
                } else {
                    setErrors({
                        ...errors,
                        general: error || 'Registration failed. Please try again.'
                    });
                }
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    return (
        <div className="auth-container">
            <form className="auth-form" onSubmit={handleSubmit}>
                {errors.general && (
                    <div className="error-message general-error">
                        <i className="fas fa-exclamation-circle"></i>
                        {errors.general}
                    </div>
                )}
                <div className="form-row">
                    <div className="form-group">
                        <label>First Name</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="first_name"
                                placeholder="Enter first name"
                                value={formData.first_name}
                                onChange={handleChange}
                                className={errors.first_name ? 'error' : ''}
                            />
                        </div>
                        {errors.first_name && <span className="error-message">
                            <i className="fas fa-exclamation-circle"></i> {errors.first_name}
                        </span>}
                    </div>

                    <div className="form-group">
                        <label>Last Name</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="last_name"
                                placeholder="Enter last name"
                                value={formData.last_name}
                                onChange={handleChange}
                                className={errors.last_name ? 'error' : ''}
                            />
                        </div>
                        {errors.last_name && <span className="error-message">
                            <i className="fas fa-exclamation-circle"></i> {errors.last_name}
                        </span>}
                    </div>
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <div className="input-container">
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            className={errors.email ? 'error' : ''}
                        />
                    </div>
                    {errors.email && <span className="error-message">
                        <i className="fas fa-exclamation-circle"></i> {errors.email}
                    </span>}
                </div>

                <div className="form-group">
                    <label>Phone Number</label>
                    <div className="input-container">
                        <input
                            type="tel"
                            name="phone_number"
                            placeholder="Enter phone number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            className={errors.phone_number ? 'error' : ''}
                        />
                    </div>
                    {errors.phone_number && <span className="error-message">
                        <i className="fas fa-exclamation-circle"></i> {errors.phone_number}
                    </span>}
                </div>

                <div className="form-group">
                    <label>Role</label>
                    <div className="input-container">
                        <select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className={errors.role ? 'error' : ''}
                        >
                            {roles.map(role => (
                                <option key={role.value} value={role.value}>
                                    {role.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <label>Password</label>
                    <div className="input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleChange}
                            className={errors.password ? 'error' : ''}
                        />
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
                        </button>
                    </div>
                    {errors.password && <span className="error-message">
                        <i className="fas fa-exclamation-circle"></i> {errors.password}
                    </span>}
                </div>

                <div className="form-group">
                    <label>Confirm Password</label>
                    <div className="input-container">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirm_password"
                            placeholder="Confirm password"
                            value={formData.confirm_password}
                            onChange={handleChange}
                            className={errors.confirm_password ? 'error' : ''}
                        />
                        <button
                            type="button"
                            className="toggle-password"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        >
                            <i className={`fas fa-eye${showConfirmPassword ? '-slash' : ''}`}></i>
                        </button>
                    </div>
                    {errors.confirm_password && <span className="error-message">
                        <i className="fas fa-exclamation-circle"></i> {errors.confirm_password}
                    </span>}
                </div>

                <button type="submit" className="submit-button">
                    Register
                </button>
            </form>
        </div>
    );
};

export default RegisterForm; 