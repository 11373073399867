import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/categories.css';
import { useNavigate } from 'react-router-dom';

const CategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('accessToken');

                if (!token) {
                    throw new Error('Authentication required');
                }

                const response = await axios.get(
                    `${config.apiUrl}/api/products/categories/`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                const categoriesData = response.data.data || [];
                setCategories(categoriesData);
                setError(null);
            } catch (err) {
                setError('Failed to load categories');
                setCategories([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const filteredCategories = categories.filter(category => {
        const searchTermLower = searchTerm.toLowerCase();
        return (
            category.name.toLowerCase().includes(searchTermLower) ||
            (category.description || '').toLowerCase().includes(searchTermLower)
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCategories = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    if (loading) {
        return (
            <div className="categories-page">
                <div className="loading-state">
                    <i className="fas fa-spinner fa-spin"></i>
                    <p>Loading categories...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="categories-page">
                <div className="error-state">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    return (
        <div className="categories-page">
            <div className="page-header">
                <h1>Categories</h1>
                <button
                    className="submit-button new-product-btn"
                    onClick={() => navigate('/dashboard/categories/add')}
                >
                    <i className="fas fa-plus"></i>
                    New Category
                </button>
            </div>

            <div className="categories-controls">
                <div className="search-container">
                    {/* <i className="fas fa-search search-icon"></i> */}
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search categories..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="categories-table">
                <div className="category-header">
                    <div className="col-name">Category Name</div>
                    <div className="col-description">Description</div>
                    <div className="col-slug">Slug</div>
                    <div className="col-parent">Parent Category</div>
                </div>

                {currentCategories.length > 0 ? (
                    currentCategories.map(category => (
                        <div
                            key={category.id}
                            className="category-item"
                            onClick={() => navigate(`/dashboard/categories/edit/${category.slug}`)}
                        >
                            <div className="col-name">
                                {category.image && (
                                    <img
                                        src={category.image}
                                        alt={category.name}
                                        className="category-image"
                                    />
                                )}
                                {category.name}
                            </div>
                            <div className="col-description">{category.description || '-'}</div>
                            <div className="col-slug">{category.slug}</div>
                            <div className="col-parent">{category.parent || '-'}</div>
                        </div>
                    ))
                ) : (
                    <div className="no-results">
                        <i className="fas fa-folder-open"></i>
                        <p>No categories found</p>
                        <span>Try adjusting your search to find what you're looking for.</span>
                    </div>
                )}
            </div>

            {totalPages > 1 && (
                <div className="pagination">
                    <button
                        className="pagination-btn"
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>

                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index + 1}
                            className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}

                    <button
                        className="pagination-btn"
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </div>
            )}
        </div>
    );
};

export default CategoriesPage; 