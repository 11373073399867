import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { authService } from '../../services/auth';
import '../../styles/layout/sidebar.css';

const Sidebar = () => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState(() => {
        const cached = localStorage.getItem('userData');
        return cached ? JSON.parse(cached) : null;
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                setLoading(true);
                const user = await authService.getCurrentUser();
                if (user) {
                    setUserData(user);
                }
            } catch (error) {
                console.error('Failed to fetch user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleLogout = () => {
        authService.logout();
        navigate('/login');
    };

    const displayName = () => {
        if (!userData) return 'Loading...';

        const firstName = userData.first_name || '';
        const lastName = userData.last_name || '';

        if (firstName || lastName) {
            return `${firstName} ${lastName}`.trim();
        }

        return userData.username || userData.phone_number || 'User';
    };

    const isAdmin = userData?.role === 'ADMIN';

    if (loading) {
        return (
            <div className="sidebar">
                <div className="seller-profile">
                    <div className="seller-info">
                        <h3>Loading...</h3>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="sidebar">
            <div className="seller-profile">
                <div className="seller-info">
                    <h3>{displayName()}</h3>
                    <p>{userData?.phone_number}</p>
                </div>
                <div className="seller-details">
                    <div className="agreement">
                        <span>Agreement</span>
                        <span>S-672214</span>
                    </div>
                    <div className="contract-date">
                        <span>Contract date</span>
                        <span>24.09.2024</span>
                    </div>
                </div>
            </div>

            <nav className="sidebar-nav">
                <NavLink to="/dashboard/products" className="nav-item">
                    <i className="fas fa-box"></i>
                    <span>My products</span>
                </NavLink>
                <NavLink to="/dashboard/draft-products" className="nav-item">
                    <i className="fas fa-file-alt"></i>
                    <span>Draft Products</span>
                </NavLink>
                <NavLink to="/dashboard/categories" className="nav-item">
                    <i className="fas fa-folder"></i>
                    <span>Categories</span>
                </NavLink>
                <NavLink to="/dashboard/import-csv" className="nav-item">
                    <i className="fas fa-file-csv"></i>
                    <span>Import CSV</span>
                </NavLink>
                <NavLink to="/dashboard/import-json" className="nav-item">
                    <i className="fas fa-file-code"></i>
                    <span>Import JSON</span>
                </NavLink>
                {isAdmin && (
                    <NavLink to="/dashboard/users" className="nav-item">
                        <i className="fas fa-users"></i>
                        <span>Users</span>
                    </NavLink>
                )}
                <NavLink to="/dashboard/settings" className="nav-item">
                    <i className="fas fa-cog"></i>
                    <span>Settings</span>
                </NavLink>
            </nav>

            <div className="help-section">
                <button className="help-button">
                    <i className="fas fa-headset"></i>
                    <span>Need help?</span>
                </button>
                <button className="help-button" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                </button>
            </div>
        </div>
    );
};

export default Sidebar; 