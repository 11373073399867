import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/AIDescriptionGenerator.css';

const AIDescriptionGenerator = ({ selectedProducts, onComplete }) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [progress, setProgress] = useState({ current: 0, total: 0 });
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const MAX_RETRIES = 3;

    const generateDescriptions = useCallback(async () => {
        if (!selectedProducts.length) return;

        setIsGenerating(true);
        setError(null);
        setProgress({ current: 0, total: selectedProducts.length });

        let currentIndex = 0;
        const token = localStorage.getItem('accessToken');

        while (currentIndex < selectedProducts.length) {
            try {
                const response = await axios.post(
                    `${config.apiUrl}/api/products/batch_generate_descriptions/`,
                    {
                        product_ids: selectedProducts,
                        current_index: currentIndex
                    },
                    {
                        headers: { 'Authorization': `Bearer ${token}` },
                        timeout: 30000
                    }
                );

                if (response.data.is_complete) {
                    setProgress({ current: selectedProducts.length, total: selectedProducts.length });
                    break;
                }

                currentIndex = response.data.current_index;
                setProgress({ current: currentIndex, total: selectedProducts.length });

            } catch (error) {
                if (retryCount < MAX_RETRIES) {
                    setRetryCount(prev => prev + 1);
                    continue;
                }
                setError('Failed to generate descriptions. Please try again.');
                break;
            }
        }

        setIsGenerating(false);
        setRetryCount(0);
        if (!error) {
            onComplete();
        }
    }, [selectedProducts, retryCount, error, onComplete]);

    return (
        <div className="ai-generator-container">
            <button
                onClick={generateDescriptions}
                disabled={isGenerating || !selectedProducts.length}
                className="generate-button"
            >
                {isGenerating ? 'Generating...' : 'Generate AI Descriptions'}
            </button>

            {isGenerating && (
                <div className="progress-container">
                    <div className="progress-bar">
                        <div
                            className="progress-fill"
                            style={{ width: `${(progress.current / progress.total) * 100}%` }}
                        />
                    </div>
                    <div className="progress-text">
                        {progress.current} of {progress.total} completed
                    </div>
                </div>
            )}

            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default AIDescriptionGenerator; 