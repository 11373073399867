import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/layout/header.css';

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Add any logout logic here (clear tokens, etc.)
        navigate('/');
    };

    return (
        <div className="dashboard-header">
            <div className="breadcrumb">
                <span>My products</span>
                <span className="separator">/</span>
                <span>All products</span>
            </div>

            <div className="header-actions">
                <button className="logout-button" onClick={handleLogout}>
                    <i className="fas fa-sign-out-alt"></i>
                    Logout
                </button>
            </div>

        </div>
    );
};

export default Header; 