import React from 'react';
import RegisterForm from '../components/Auth/RegisterForm';
import '../styles/pages/login.css';

const RegisterPage = () => {
    return (
        <div className="login-page">
            <div className="login-container">
                <div className="login-header">
                    <h1 className="brand-name">Taqqosla</h1>
                    <p className="brand-subtitle">Seller Portal</p>
                </div>
                <RegisterForm />
                <div className="login-footer">
                    <p>Already have an account? <a href="/">Login</a></p>
                    <p className="help-text">
                        Need help? <a href="/contact">Contact Support</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage; 