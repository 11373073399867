import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/addProduct.css';


const AddProductPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        old_price: '',
        stock: '',
        status: 'DRAFT',
        category: '',
        imageUrl1: '',
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        url: ''
    });

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await axios.get(
                `${config.apiUrl}/api/products/categories/`,
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );
            setCategories(response.data.data || []);
        } catch (err) {
            setError('Failed to load categories');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const token = localStorage.getItem('accessToken');

            const images = [
                formData.imageUrl1,
                formData.imageUrl2,
                formData.imageUrl3,
                formData.imageUrl4
            ].filter(url => url.trim());

            const payload = {
                name: formData.name,
                description: formData.description,
                price: parseFloat(formData.price),
                old_price: formData.old_price ? parseFloat(formData.old_price) : null,
                stock: parseInt(formData.stock),
                category: parseInt(formData.category),
                status: formData.status,
                images: images,
                url: formData.url || null
            };

            await axios.post(
                `${config.apiUrl}/api/products/products/`,
                payload,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            navigate('/dashboard/products');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to create product');
        } finally {
            setLoading(false);
        }
    };

    // Get status choices from the Product model
    const STATUS_CHOICES = {
        DRAFT: 'Draft',
        PUBLISHED: 'Published',
        OUT_OF_STOCK: 'Out of Stock',
        ARCHIVED: 'Archived'
    };

    return (
        <div className="add-product-page">
            <div className="add-product-header">
                <h2>Add New Product</h2>
                <button
                    className="back-button"
                    onClick={() => navigate('/dashboard/products')}
                >
                    <i className="fas fa-arrow-left"></i> Back
                </button>
            </div>

            {error && <div className="error-message">{error}</div>}

            <form onSubmit={handleSubmit} className="add-product-form">
                <div className="form-group">
                    <label>Product Name *</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        maxLength="200"
                        placeholder="Enter product name"
                    />
                </div>

                <div className="form-group">
                    <label>Description *</label>
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                        placeholder="Enter product description"
                        rows="4"
                    />
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Price *</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                            step="0.01"
                            min="0"
                            placeholder="0.00"
                        />
                    </div>

                    <div className="form-group">
                        <label>Old Price</label>
                        <input
                            type="number"
                            name="old_price"
                            value={formData.old_price}
                            onChange={handleChange}
                            step="0.01"
                            min="0"
                            placeholder="0.00"
                        />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group">
                        <label>Stock *</label>
                        <input
                            type="number"
                            name="stock"
                            value={formData.stock}
                            onChange={handleChange}
                            required
                            min="0"
                            placeholder="0"
                        />
                    </div>

                    <div className="form-group">
                        <label>Status *</label>
                        <select
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            required
                        >
                            {Object.entries(STATUS_CHOICES).map(([value, label]) => (
                                <option key={value} value={value}>
                                    {label}
                                </option>
                            ))}
                        </select>
                        <small>
                            Note: Products with zero stock will automatically be marked as Out of Stock
                        </small>
                    </div>
                </div>

                <div className="form-group">
                    <label>Category *</label>
                    <select
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Image URLs</label>
                    <div className="image-urls-container">
                        <input
                            type="url"
                            name="imageUrl1"
                            value={formData.imageUrl1}
                            onChange={handleChange}
                            placeholder="Enter image URL 1"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl2"
                            value={formData.imageUrl2}
                            onChange={handleChange}
                            placeholder="Enter image URL 2"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl3"
                            value={formData.imageUrl3}
                            onChange={handleChange}
                            placeholder="Enter image URL 3"
                            className="image-url-input"
                        />
                        <input
                            type="url"
                            name="imageUrl4"
                            value={formData.imageUrl4}
                            onChange={handleChange}
                            placeholder="Enter image URL 4"
                            className="image-url-input"
                        />
                    </div>
                    <small>Enter up to 4 image URLs. Empty URLs will be ignored.</small>
                </div>

                <div className="form-group">
                    <label>Product URL (Optional)</label>
                    <input
                        type="url"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        placeholder="Enter product URL"
                    />
                    <small>External link to the product (if available)</small>
                </div>

                <div className="form-actions">
                    <button
                        type="button"
                        className="cancel-button"
                        onClick={() => navigate('/dashboard/products')}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Product'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddProductPage; 