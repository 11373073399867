import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config';
import { useNavigate } from 'react-router-dom';
import '../../styles/pages/dashboard/products.css';

const UsersPage = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const userResponse = await axios.get(`${config.apiUrl}/api/users/me/`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });

                setUserRole(userResponse.data.role);

                if (userResponse.data.role === 'ADMIN') {
                    const response = await axios.get(`${config.apiUrl}/api/users/list/`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    setUsers(response.data);
                }
                setLoading(false);
            } catch (err) {
                console.error('Error fetching users:', err);
                setError('Failed to load users');
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleUserClick = (userId) => {
        navigate(`/dashboard/users/${userId}`);
    };

    if (loading) {
        return (
            <div className="products-page">
                <div className="loading-state">
                    <i className="fas fa-spinner fa-spin"></i>
                    <p>Loading users...</p>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="products-page">
                <div className="error-state">
                    <i className="fas fa-exclamation-circle"></i>
                    <p>{error}</p>
                </div>
            </div>
        );
    }

    if (userRole !== 'ADMIN') {
        return (
            <div className="products-page">
                <div className="error-state">
                    <i className="fas fa-lock"></i>
                    <h2>Access Denied</h2>
                    <p>You don't have permission to view this page.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="products-page">
            <div className="page-header">
                <div className="header-content">
                    <h1>Users</h1>
                </div>
                <button
                    className="submit-button new-product-btn"
                    onClick={() => navigate('/dashboard/users/add')}
                >
                    <i className="fas fa-plus"></i>
                    New user
                </button>
            </div>

            <div className="products-list">
                <div className="products-table">
                    <div className="user-table-header">
                        <div className="col-id">ID</div>
                        <div className="col-name">Username</div>
                        <div className="col-status">Role</div>
                        <div className="col-category">Email</div>
                        <div className="col-price">Phone</div>
                    </div>

                    {users.length > 0 ? (
                        users.map(user => (
                            <div
                                key={user.id}
                                className="user-item"
                                onClick={() => handleUserClick(user.id)}
                            >
                                <div className="col-id">{user.id}</div>
                                <div className="col-name">{user.username}</div>
                                <div className="col-status">
                                    <span className={`status-badge ${user.role.toLowerCase()}`}>
                                        {user.role}
                                    </span>
                                </div>
                                <div className="col-category">{user.email}</div>
                                <div className="col-price">{user.phone_number || '-'}</div>
                            </div>
                        ))
                    ) : (
                        <div className="no-results">
                            <i className="fas fa-users"></i>
                            <p>No users found</p>
                            <span>There are no registered users in the system.</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UsersPage; 