import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { config } from '../../config';
import '../../styles/pages/dashboard/common.css';
import '../../styles/pages/dashboard/SettingsPage.css';

const SettingsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [progress, setProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('DRAFT');
    const [isFetchingCategories, setIsFetchingCategories] = useState(true);
    const [username, setUsername] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                setIsFetchingCategories(true);
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/products/categories/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setCategories(response.data.data || []);
            } catch (err) {
                console.error('Error fetching categories:', err);
                setError('Failed to load categories');
                setCategories([]);
            } finally {
                setIsFetchingCategories(false);
            }
        };

        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(
                    `${config.apiUrl}/api/users/me/`,
                    {
                        headers: { 'Authorization': `Bearer ${token}` }
                    }
                );
                setUserRole(response.data.role);
                setUsername(response.data.username);
                if (response.data.default_category_id) {
                    setSelectedCategory(response.data.default_category_id.toString());
                }
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('Failed to load user data');
            }
        };

        fetchCategories();
        fetchUserData();
    }, []);

    const handleGenerateProducts = async () => {
        if (!selectedCategory) {
            setError('Please select a category');
            return;
        }

        try {
            setIsLoading(true);
            setError(null);
            setInfoMessage('Starting product generation...');
            setProgress(0);

            const token = localStorage.getItem('accessToken');

            // Step 1: Trigger scraping
            console.log('Starting product scraping...');
            const scrapeResponse = await axios.post(
                `${config.apiUrl}/api/products/scrape-products/`,
                {
                    username,
                    category: selectedCategory,
                    status: selectedStatus
                },
                {
                    headers: { 'Authorization': `Bearer ${token}` }
                }
            );

            // Log the scraping results
            console.log('Scraping Response:', {
                success: scrapeResponse.data.success,
                counts: scrapeResponse.data.counts_summary,
                newProductsCount: scrapeResponse.data.products.length,
                errors: scrapeResponse.data.errors
            });

            if (!scrapeResponse.data.success) {
                throw new Error(scrapeResponse.data.message || 'Scraping failed');
            }

            setProgress(50);
            setInfoMessage('Products scraped successfully. Starting import...');

            // Step 2: Import the scraped products
            console.log('Starting product import...');
            const formData = new FormData();
            formData.append('json_data', JSON.stringify(scrapeResponse.data.products));
            formData.append('category', selectedCategory);
            formData.append('status', selectedStatus);

            const importResponse = await axios.post(
                `${config.apiUrl}/api/products/products/import_json/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                    timeout: 300000,
                }
            );

            console.log('Import Response:', {
                imported: importResponse.data.imported_count,
                failed: importResponse.data.failed_count,
                existing: importResponse.data.existing_count,
                errors: importResponse.data.errors
            });

            setProgress(100);

            // Use the existing_products count from scraping response instead of import response
            const { imported_count, failed_count } = importResponse.data;
            const existing_count = scrapeResponse.data.counts_summary.existing_products;

            if (imported_count === 0 && existing_count > 0 && failed_count === 0) {
                setInfoMessage(`All ${existing_count} products already exist in the database`);
            } else {
                setInfoMessage(`Import completed: ${imported_count} products imported, ${failed_count} failed, ${existing_count} already exist`);
            }

        } catch (err) {
            console.error('Generation error:', err);
            console.error('Error details:', {
                response: err.response?.data,
                message: err.message
            });
            setError(err.response?.data?.errors?.[0] || err.response?.data?.message || err.message || 'Failed to generate products');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="dashboard-page">
            <div className="page-header">
                <h1>Settings</h1>
            </div>
            <div className="content-box">
                {error && <div className="error-message">{error}</div>}
                {infoMessage && <div className="info-message">{infoMessage}</div>}

                {userRole === 'SELLER' && (
                    <div className="settings-section">
                        <h2>Product Generation</h2>
                        <p>Generate products automatically from your store</p>

                        <div className="form-group">
                            <label>Category *</label>
                            {isFetchingCategories ? (
                                <div className="loading-text">Loading categories...</div>
                            ) : (
                                <select
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    required
                                    disabled={isLoading}
                                >
                                    <option value="">Select a category</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>

                        <div className="form-group">
                            <label>Initial Status *</label>
                            <select
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                required
                                disabled={isLoading}
                            >
                                <option value="DRAFT">Draft</option>
                                <option value="PUBLISHED">Published</option>
                                <option value="OUT_OF_STOCK">Out of Stock</option>
                                <option value="ARCHIVED">Archived</option>
                            </select>
                        </div>

                        <button
                            className="generate-button"
                            onClick={handleGenerateProducts}
                            disabled={isLoading || !selectedCategory}
                        >
                            {isLoading ? 'Generating...' : 'Generate Products'}
                        </button>

                        {isLoading && progress > 0 && (
                            <div className="progress-container">
                                <div className="progress-bar">
                                    <div
                                        className="progress-fill"
                                        style={{ width: `${progress}%` }}
                                    ></div>
                                </div>
                                <span className="progress-text">{progress}% Complete</span>
                            </div>
                        )}
                    </div>
                )}

                {userRole && userRole !== 'SELLER' && (
                    <div className="info-message">
                        This feature is only available for sellers.
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsPage; 